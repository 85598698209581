
.home {
    max-width: $breakpoint-screen;
    margin: auto;
    text-align: center;
}



@media screen and (min-width: $breakpoint-screen) {
    .home {
        display: grid;
        grid-template-columns: 30% auto;
        text-align: left;
        border-top:  1px solid rgba(0, 0, 0, 0.1);;
        border-bottom:  1px solid rgba(0, 0, 0, 0.1);
    }

        .home img {
            width: 200px;
            margin: auto;
            
        }
        .home div {
            align-self: center;
        }
}