// .controls {
//     display: none;
// }


// @media screen and (min-width: $breakpoint-screen) {
    .carousel-section {
        max-width: none;
        width: 100%;
        padding: 0em 2em 2em 4em;
    
    text-align: left;
    }

    .carousel {
    
        height: 90%;
        padding: 1em 0em 1em 0em;
        overflow: hidden;
        position: relative;
    }
    .slider {
        height: 100%;
        padding: 1em 0em 1em 0em;
        // background: lightpink;
        display: flex;

        
        // width: 800%;
        transition: all 0.3s;
        
    }
    .slide {
        flex-basis: 100%;
        padding: 1em 2em 1em 2em;
        text-align: center;
    }
    .slide img {
        width: 70%
    }
    .slide .note-video {
        font-size: 1em;
        font-style: italic;
    }
    .controls {
        display: block;
    }

    .controls .arrow {
        position: absolute;
        top: 40%;
        cursor: pointer;
        transform: translateY(-50%);

    }

    .arrow.left {
        left: 0em;
        
    }
    .controls i{
        color: $menu-color;
    }

    .arrow.right {
        right: 0em;
    }

    .controls ul {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%);
        list-style: none;
        display: flex;
    
        
    }

    .controls ul li {
        width: 10px;
        height: 10px;
        border: 1px solid $menu-color;
        margin: 5px;
        border-radius: 10px;
        cursor: pointer;
    }

    .controls ul li.selected{
        background-color: $menu-color ;
    }

// }