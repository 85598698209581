.splashfooter {
    position:relative;
    top: 100px;
    width: 600px;
    z-index: -1000;
}
.splashfooter {
    width: 100%;
}


footer {
    background: $footer-color;
    color: white;
    padding-top: 2em;
    width: 100%;
    text-align: center;
}

 i{
     color: white;
 }


 footer .footernav ul{
    list-style: none;
    text-align: center;

 }
 footer a {
     text-decoration: none;
     color: white;
 }
 footer a:hover{
     color: gray;
 }

 footer .footersocial i {
    padding: 0.5em
 }
 footer img {
     width: 300px;
 }

 @media screen and (min-width: $breakpoint-screen) {
    footer {
         display: grid;
         grid-template-columns: 1fr 1fr 1fr;
         text-align: left;
         padding: 1em;
     }
     footer .footernav ul{
        text-align: left;
    
     }
     footer .footersocial {
         text-align: center;
         padding: 0.5em;
         
     }

 }