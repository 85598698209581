
.events .event-subtitle {
  font-weight: 600;
}

.event-img {
  width: 70%;
}

.event-fee-table {
  display: grid;
  grid-template-columns: 80% auto;
  
}
.event-fee-table div {
  border: 1px solid gray;
}
.event-fee-table p{
  text-align: center;
}


@media screen and (min-width: $breakpoint-screen) {
  .events {
    display: grid;
    grid-template-columns: 30% auto;
  }
  .events .event-title {
    grid-column: 1/3;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .events .event-subtitle {
    text-align: right;
    padding-right: 1em;
  }
  .events .event-text {
    text-align: left;
  }
}
