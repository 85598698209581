

.discuss {
    max-width: $breakpoint-screen;
    margin: auto;
    text-align: center;

}

.ahaslides-entry-container {
    position: relative;
    height: 180px;
    overflow: hidden;
    
}

.ahaslides-entry-container iframe {
    position:absolute;
    left: 0;
    top: -160px;
    z-index: 1000;
    
}

/* Word cloud container*/
.ahaslides-wordcloud-container {
    position: relative;
    height: 162px;
    border: 1px solid $menu-color;
    overflow: hidden;
    margin-top: 1em;
}

.ahaslides-wordcloud-container iframe {
    position: absolute;
    left: 0;
    top: -190px;
      
}

/* END word cloud container (for normal mobile view)*/

.wordcloud .chart{
    border: 1px solid $menu-color;
}

.wordcloud form {
    max-width: 50%;
    margin: auto;
}


@media screen and (min-width: $breakpoint-screen) {
        .discuss {
            position: relative;
            padding-top: 0em;
            display: grid;
            grid-template-columns: 30% auto;
            text-align: left;
        }
        .discuss h1 {
            text-align: left;
           grid-column: 1 / 3;
           border-bottom:  1px solid rgba(0, 0, 0, 0.1);
        }
        
        .discuss img {
            width:70%;
            
        }
        .discuss .footer-quote-text {
            color: $italic-color;
            
            font-style: italic;
            text-align: left;
            border-left:  1px solid rgba(0, 0, 0, 0.1);
            
            padding-left: 1em;
        }   
        .discuss .footer-quote {
            color: $quote-signature;
            font-weight: 700;
            font-style: italic;
            text-align: right;
        }

        .ahaslides-entry-container {
            position: relative;
            height: 180px;
            overflow: hidden;
            
        }

        .ahaslides-entry-container iframe {
            position:absolute;
            left: 0;
            top: -160px;

        }

        /* Word cloud container*/
        .ahaslides-wordcloud-container {
            position: relative;
            height: 395px;
            border: 1px solid $menu-color;
            overflow: hidden;
            margin-top: 1em;
        }

        .ahaslides-wordcloud-container iframe {
            position: absolute;
            left: 0;
            top: -95px;
            
        }

        /* END word cloud container (for normal mobile view)*/



}