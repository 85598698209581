
.listen {
    max-width: $breakpoint-screen;
    margin: auto;
    text-align: center;
}
.subtitle1 {
    font-size: 25px;
}
.subtitle2 {
    font-size: 40px;
    line-height: 1.3em;
}
.subtitle3 {
    font-size: 25px;
}


@media screen and (min-width: $breakpoint-screen) {
    .listen {
        display: grid;
        grid-template-columns: 30% auto;
        text-align: left;
        
        // border-bottom:  1px solid rgba(0, 0, 0, 0.1);
    }
    .listen h1 {
        text-align: left;
       grid-column: 1 / 3;
       border-bottom:  1px solid rgba(0, 0, 0, 0.1);
    }
    
    .listen img {
        width:70%;
        
    }
    .listen .footer-quote-text {
        color: $italic-color;
        
        font-style: italic;
        text-align: left;
        border-left:  1px solid rgba(0, 0, 0, 0.1);
        
        padding-left: 1em;
    }   
    .listen .footer-quote {
        color: $quote-signature;
        font-weight: 700;
        font-style: italic;
        text-align: right;
    }


    
}