
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,*::before,*::after {
    box-sizing: border-box;
}

body {
    
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    line-height: 1.7em;

}

html {
    scroll-behavior: smooth;
}

video {
    width: 100%;
    height: auto;
    border-radius: 6px;
}

img {
    width: 50%;
    border-radius: 6px;
}

.acuarela {
    position: absolute;
    width: 100%;
    z-index: -1000;
}

.vimeo {
    height: 300px;
    width: 100%;
    
}
.vimeo iframe {
    height: 100%;
    width: 100%;
}


#videoaskframe {
    border: none;
    border-radius: 12px;
}

.introsection,
.listen,
.imagine,
.act,
.contact,
section
 {
   padding: 4em 1em 0em 1em;
   max-width: $breakpoint-screen;
   margin: auto;
   text-align: center;

}

section {
    padding: 1em 1em 0em 1em;
}

.introsection {

    text-align: center;
    padding-bottom: em;
    
}
h1 {
    color: $titleh1-color;
    font-size: 50px;
    line-height: 2.5em;
}


.introsection h1 {
    font-size: 3em;
    color: $titleh1-color;
    font-weight: 300;
    line-height: 1em;

}

.introsection h2 {
    font-size: 1.5em;
    color: $titleh1-color;
    font-weight: 300;
}

.introsection h2 span,
.introsection h1 span {
    font-weight: 900;
}

button {
    background: transparent;
    text-decoration: none;
    font-family: unset;
    font-weight: 300;
    font-size: 1em;
    padding: 1em 1em 1em 1em;
    border: 1px solid black;
    cursor: pointer;
}
button:hover {
    transform:scale(1.01);
}

.left-splash {
    position: absolute;
    left: 0;
    width: 20%;
    z-index: -999;
}
.rigth-splash {
    position: absolute;
    right: 0;
    width: 20%;
    z-index: -999;
}

@media screen and (min-width: $breakpoint-screen) {
    .acuarela {
        position: absolute;
        width: 1000px;
        z-index: -1000;
    }

    .left-splash {
        position: absolute;
        left: 0;
        width: 300px;
        z-index: -999;
    }
    .rigth-splash {
        position: absolute;
        right: 0;
        width: 300px;
        z-index: -999;
    }

    .vimeo {
        height: 500px;
        width: 100%;
        
    }
    .vimeo iframe {
        height: 100%;
        width: 100%;
    }

 
}