


.imagine {
    max-width: $breakpoint-screen;
    margin: auto;
    text-align: center;
    
}



@media screen and (min-width: $breakpoint-screen) {
        .imagine {
            position: relative;
            padding-top: 0em;
            display: grid;
            grid-template-columns: 30% auto;
            text-align: left;
            
        }
        .imagine h1 {
            text-align: left;
           grid-column: 1 / 3;
           border-bottom:  1px solid rgba(0, 0, 0, 0.1);
        }
        
        .imagine img {
            width:70%;
            
        }
        .imagine .footer-quote-text {
            color: $italic-color;
            
            font-style: italic;
            text-align: left;
            border-left:  1px solid rgba(0, 0, 0, 0.1);
            
            padding-left: 1em;
        }   
        .imagine .footer-quote {
            color: $quote-signature;
            font-weight: 700;
            font-style: italic;
            text-align: right;
        }

 
}