
/* navigation bar and header styles */

header {
    color: $menu_color;
    text-align: center;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: white;
    height: 60px;
    box-shadow: 0 3px 20px rgba(0, 0 ,0, 0.2 );
}

 .logo {
    display: none;
}


.nav-toggle {
    display: none;
    
}

.nav-toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1em;
    height: 100%;
    display: flex;
    align-items:center;
    
}
 .nav-toggle-label span,
 .nav-toggle-label span::before,
 .nav-toggle-label span::after {
     display: block;
     background: black;
     height: 2px;
     width: 2em;
     border-radius: 2px;
     position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
    content: '';
    position: absolute;
}

.nav-toggle-label span::before {
    bottom: 7px;
}

.nav-toggle-label span::after {
    top: 7px;
}

header nav {

    background:  white;
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    box-shadow: 0 3px 20px rgba(0, 0 ,0, 0.2 );
    width: 100%;
    transform: scale(1,0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;

}

header nav ul {
    
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
}

header nav li {
    
    margin-bottom: 1em;
    margin-left: 1em;
}
header nav li img {
    width: 50px;
}

header nav a {
    color: $menu_color;
    font-weight: 300;
    text-decoration: none;
    font-size: 1.2em; 
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}
header nav a:hover {
    color: #000;
}

.nav-toggle:checked ~ nav {
    transform: scale(1,1)
}

.nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
}


@media screen and (min-width: $breakpoint-screen) {
    .nav-toggle-label {
        display: none;

    }
    header {
        background: transparent;
        display: grid;
        grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
        position:static;
        box-shadow: none;
    }

    .logo {
        display: block;
        grid-column: 2 / 3;
        align-self:flex-end;
        
        width: 25%;
    }
    header nav {
        all: unset;
        grid-column: 3 / 4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: transparent;
        

    }
    header nav ul {
        display: flex;
        justify-content: flex-end;
    }
    header nav li {
        margin-left: 3em;
        margin-bottom: 0;
    }


    header nav a {
        opacity: 1;
        position: relative;
    }

    header nav a::before {
        content: '';
        display: block;
        height: 5px;
        background: black;
        position: absolute;
        top: -0.75em;
        left: 0;
        right: 0;
        transform: scale(0,1);
        transform-origin: right;
        transition: transform ease-in-out 250ms;
    }

    header nav a:hover::before {
        transform: scale(1,1);
    }
    
}

/* Language buttons */

li button {
    margin: 2px;
    border: none;
}

li button:hover{
    color: gray;
}

   