

$oscuro-fuerte: #333;
$menu_color: #696969;
$italic-color: #666666;
$quote-signature:  #393939;


$background: white;
$footer-color: #305081;
$titleh1-color: #133556;

$breakpoint-screen: 900px;

