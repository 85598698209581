
.backgroundvideo {
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #111;
    color: white;
    z-index: 2;
}

.backgroundvideo video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;

}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.text {
    position: relative;
    z-index: 10;
}
.text h2 {
    font-size: 5em;
    font-weight: 800;
    line-height: 1em;
    text-transform: uppercase;
}

.text p{
    font-size: 1.1em;
    margin: 20px 0;
    font-weight: 400;
    max-width: 700px;
}

.text a {
    display: inline-block;
    font-size: 1em;
    background: white;
    padding: 10px 30px;
    text-decoration: none;
    color: #111;
    margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.2s;
}

.text a:hover {
    letter-spacing: 6px;
}